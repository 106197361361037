<div class="grid-fluid">
  <div class="footer-block" *ngFor="let node of nodes">
    <h3>{{node.title}}</h3>
    <ul>
      <li *ngFor="let item of node.children">
        <a class="link" [href]="item.url" [title]="item.tooltip || item.title">{{ item.title }}</a>
      </li>
    </ul>
  </div>
</div>

<p>
  Super-powered by JioDev ©2018-2019.
  Code licensed under an <a href="license" title="License text">MIT-style License</a>.
  Documentation licensed under
  <!-- <a href="http://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>. -->
</p>
<p>
  Version {{versionInfo?.full}}.
</p>